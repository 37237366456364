import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import {
  OrganizationJsonLd,
  ProductJsonLd,
  FAQPageJsonLd,
  BreadcrumbJsonLd,
  ArticleJsonLd,
} from 'next-seo'
import { delHtmlTag } from '@lib/utils/tools'
import { tagsToCollection } from '@lib/utils/thirdparty'

const JsonLd = ({
  type,
  pageType,
  product,
  blog,
  articles,
  metafields,
  slug,
  name,
}) => {
  const router = useRouter()
  const { locale, asPath } = router
  const { components, reviewsSettings = {}, pageCommon = {} } = metafields || {}
  const { faq } = components || {}

  const foundArticle = useMemo(
    () => articles?.find((item) => item?.handle === slug),
    [articles, slug]
  )

  const followCollection = useMemo(() => {
    if (type === 'product' && product) {
      return tagsToCollection(product?.tags)
    }
    return false
  }, [product, type])

  return (
    <>
      <OrganizationJsonLd
        type="Corporation"
        id="https://www.anker.com/"
        logo="https://www.anker.com/icon.png"
        legalName="Anker Innovations"
        name="Anker"
        sameAs={[
          'https://www.facebook.com/Anker.fans',
          'https://twitter.com/AnkerOfficial',
          'https://www.youtube.com/user/AnkerOceanwing',
          'https://www.instagram.com/anker_official/',
        ]}
        url="https://www.anker.com/"
      />
      {/* {type === 'product' && product && (
        <ProductJsonLd
          productName={(product?.name || '').replace(/"/g, '\\"')}
          images={product?.images?.map((item) => item?.url)}
          description={delHtmlTag(product?.description || '').replace(
            /"/g,
            '\\"'
          )}
          brand="Anker"
          aggregateRating={aggregateRating}
          reviews={reviews}
          offers={
            product?.variants && product?.variants?.length > 0
              ? {
                  price: product?.variants?.[0]?.price,
                  lowPrice: product?.variants?.[0]?.price,
                  highPrice:
                    product?.variants?.[0]?.listPrice ||
                    product?.variants?.[0]?.price,
                  priceCurrency: product?.price?.currencyCode,
                  offerCount: product?.variants?.[0]?.availableForSale
                    ? 999
                    : 0,
                  itemCondition: 'https://schema.org/UsedCondition',
                  availability: 'https://schema.org/InStock',
                  url: `https://www.anker.com${
                    locale === 'us' ? asPath : '/' + locale + asPath
                  }`,
                }
              : {
                  price: product?.price?.value,
                  priceCurrency: product?.price?.currencyCode,
                  offerCount: product?.availableForSale ? 999 : 0,
                  itemCondition: 'https://schema.org/UsedCondition',
                  availability: 'https://schema.org/InStock',
                  url: `https://www.anker.com${
                    locale === 'us' ? asPath : '/' + locale + asPath
                  }`,
                }
          }
        />
      )} */}
      {(type === 'product' || type === 'page') && faq && (
        <FAQPageJsonLd
          mainEntity={faq?.items?.map((item) => ({
            questionName: delHtmlTag(item?.question || '').replace(/"/g, '\\"'),
            acceptedAnswerText: delHtmlTag(item?.answer || '').replace(
              /"/g,
              '\\"'
            ),
          }))}
        />
      )}

      {(type === 'collection' || type === 'article') && metafields?.faq && (
        <FAQPageJsonLd
          mainEntity={metafields?.faq.list?.map((item) => ({
            questionName: delHtmlTag(item?.q || '').replace(/"/g, '\\"'),
            acceptedAnswerText: delHtmlTag(item?.a || '').replace(/"/g, '\\"'),
          }))}
        />
      )}

      {type === 'product' && product && (
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: pageType === 'charger' ? 'Home' : 'Anker SOLIX',
              item: `https://www.anker.com${
                locale === 'us' ? '' : '/' + locale
              }${pageType === 'charger' ? '/' : '/anker-solix'}`,
            },
            {
              position: 2,
              name: followCollection?.name,
              item: `https://www.anker.com${
                locale === 'us'
                  ? `/collections/${followCollection?.handle}`
                  : `/${locale}/collections/${followCollection?.handle}`
              }`,
            },
            {
              position: 3,
              name: product?.name,
              item: `https://www.anker.com${
                locale === 'us'
                  ? `/products/${product?.handle}`
                  : `/${locale}/products/${product?.handle}`
              }`,
            },
          ]}
        />
      )}

      {type === 'blog' && (
        <>
          <ArticleJsonLd
            url={`https://www.anker.com${locale === 'us' ? '' : '/' + locale}/${
              blog.handle
            }`}
            title={blog?.title}
            images={blog?.articles.map((item) => item?.image?.url)}
          />
          <BreadcrumbJsonLd
            itemListElements={[
              {
                position: 1,
                name: pageType === 'charger' ? 'Home' : 'Anker SOLIX',
                item: `https://www.anker.com${
                  locale === 'us' ? '' : '/' + locale
                }${pageType === 'charger' ? '/' : '/anker-solix'}`,
              },
              {
                position: 2,
                name: 'Blog Center',
                item: `https://www.anker.com${
                  locale === 'us' ? '' : '/' + locale
                }/blogs/${blog.handle}`,
              },
            ]}
          />
        </>
      )}

      {type === 'article' && (
        <>
          <ArticleJsonLd
            url={`https://www.anker.com${
              locale === 'us' ? '' : '/' + locale
            }/blogs/${blog}/${slug}`}
            title={foundArticle?.title}
            images={[foundArticle?.image?.url]}
            datePublished={foundArticle?.publishedAt}
            description={foundArticle?.seo?.description}
          />

          <BreadcrumbJsonLd
            itemListElements={[
              {
                position: 1,
                name: pageType === 'charger' ? 'Home' : 'Anker SOLIX',
                item: `https://www.anker.com${
                  locale === 'us' ? '' : '/' + locale
                }`,
              },
              {
                position: 2,
                name: 'Blog Center',
                item: `https://www.anker.com${
                  locale === 'us' ? '' : '/' + locale
                }/blogs/home`,
              },
              {
                position: 3,
                name: blog,
                item: `https://www.anker.com${
                  locale === 'us' ? '' : '/' + locale
                }/blogs/${blog}`,
              },
              {
                position: 4,
                name: slug,
                item: `https://www.anker.com${
                  locale === 'us'
                    ? `/blogs/${blog}/${slug}`
                    : `/${locale}/blogs/${blog}/${slug}`
                }`,
              },
            ]}
          />
        </>
      )}

      {type === 'page' && slug && (
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: pageType === 'charger' ? 'Home' : 'Anker SOLIX',
              item: `https://www.anker.com${
                locale === 'us' ? '' : '/' + locale
              }${pageType === 'charger' ? '/' : '/anker-solix'}`,
            },
            {
              position: 2,
              name: pageCommon?.crumbName || slug,
              item: `https://www.anker.com${
                locale === 'us'
                  ? `${
                      pageType === 'charger'
                        ? ''
                        : slug?.includes('anker-solix')
                        ? ''
                        : '/anker-solix'
                    }${slug}`
                  : `/${locale}${
                      pageType === 'charger'
                        ? ''
                        : slug?.includes('anker-solix')
                        ? ''
                        : '/anker-solix'
                    }${slug}`
              }`,
            },
          ]}
        />
      )}

      {type === 'collection' && name && (
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: pageType === 'charger' ? 'Home' : 'Anker SOLIX',
              item: `https://www.anker.com${
                locale === 'us' ? '' : '/' + locale
              }${pageType === 'charger' ? '/' : '/anker-solix'}`,
            },
            {
              position: 2,
              name: name,
              item: `https://www.anker.com${
                locale === 'us'
                  ? `/collections/${slug}`
                  : `/${locale}/collections/${slug}`
              }`,
            },
          ]}
        />
      )}
    </>
  )
}

export default JsonLd
